import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VendorDashboard = () => {
    const { auth } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
                setUser(res.data);  // Save the vendor user data
            } catch (err) {
                console.error(err);
            }
        };

        if (auth.token) {
            fetchUser();  // Fetch vendor details if token is available
        }
    }, [auth.token]);

    if (!auth.token || !user) {
        return <div>Loading...</div>;  // Show loading state while user data is being fetched
    }

    const handleViewProfile = () => {
        navigate('/vendor-profile');
    };

    const handleViewBenchList = () => {
        navigate('/vendor-benchlist');
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto py-12 px-6">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Vendor Dashboard</h1>
                <span className="text-gray-800">Welcome, {user.name}!</span> {/* Ensure user.name is only accessed after user data is loaded */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                    {/* Dashboard Cards */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Profile</h2>
                        <p className="text-gray-600">View and edit your profile details.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={handleViewProfile}>
                            View Profile
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Add BenchList</h2>
                        <p className="text-gray-600">Add new candidates to your portfolio.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={handleViewBenchList}>
                            Add Candidates
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Track Applications</h2>
                        <p className="text-gray-600">Track the job applications of your candidates.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600">
                            Track Applications
                        </button>
                    </div>
                </div>
            </div>

            
            <div className="flex justify-center mt-8">
                <button
                    onClick={logout}
                    className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
                >
                    Logout
                </button>
            </div>
        </div>
    );
};

export default VendorDashboard;
