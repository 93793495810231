import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Box } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const FileUploadAndList = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user's previously uploaded data
    const fetchUploadedData = async () => {
      try {
        const response = await axios.get('https://besto-hrms.onrender.com/api/vendor/bench-list'); // Adjust the endpoint as necessary
        setUploadedData(response.data);
      } catch (error) {
        console.error('Error fetching uploaded data:', error);
      } finally {
        setLoadingData(false);
      }
    };
    fetchUploadedData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setUploading(true);
      const response = await axios.post('https://besto-hrms.onrender.com/api/vendor/upload-excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.newEntries && Array.isArray(response.data.newEntries)) {
        setUploadedData((prev) => [...prev, ...response.data.newEntries]);
      } else {
        console.error('Unexpected response format:', response.data);
        setMessage('Unexpected response from the server.');
      }
  
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Failed to upload file.');
    } finally {
      setUploading(false);
    }
  };

  // Group uploaded data by fileName
  const groupedData = uploadedData.reduce((groups, entry) => {
    const fileName = entry.fileName;
    if (!groups[fileName]) {
      groups[fileName] = [];
    }
    groups[fileName].push(entry);
    return groups;
  }, {});

  // Handle the Back button to navigate to the vendor dashboard
  const handleBack = () => {
    navigate('/vendor-dashboard');
  };

  return (
    <Container maxWidth="lg" className="mt-10 p-6 bg-white shadow-lg rounded-lg">
      {/* Back button at the top */}
      <Box className="flex justify-between items-center mb-6">
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          className="h-12"
        >
          Back to Dashboard
        </Button>
        
      </Box>

      <Typography variant="body1" className="text-center text-gray-600 mb-6">
        Upload your BenchList.
      </Typography>

      {/* File upload form */}
      <form onSubmit={handleSubmit} className="flex flex-col items-center mb-6">
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          className="mb-4 border border-gray-300 rounded-lg p-2 w-72"
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<CloudUpload />}
          disabled={uploading}
          className="w-40 h-12"
        >
          {uploading ? <CircularProgress size={24} color="white" /> : 'Upload'}
        </Button>
      </form>

      {/* Display message */}
      {message && <Typography variant="subtitle1" className="text-red-500 mb-5">{message}</Typography>}

      <Typography variant="h5" className="mb-4 text-center font-semibold text-gray-800">
        Your Uploaded Lists
      </Typography>

      {/* Data Display */}
      {loadingData ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : Object.keys(groupedData).length === 0 ? (
        <Typography variant="body1" className="text-center text-gray-500">
          No data uploaded yet.
        </Typography>
      ) : (
        Object.keys(groupedData).map((fileName) => (
          <div key={fileName} className="mb-6">
            <Typography variant="h6" className="font-semibold text-gray-800 mb-4">
              Data from: {fileName}
            </Typography>
            <TableContainer component={Paper} elevation={3} className="shadow-md">
              <Table>
                <TableHead className="bg-blue-100">
                  <TableRow>
                    <TableCell>Module</TableCell>
                    <TableCell>Skills</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Total Exp</TableCell>
                    <TableCell>Relevant Exp</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Availability</TableCell>
                    <TableCell>Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedData[fileName].map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.module}</TableCell>
                      <TableCell>{entry.skills}</TableCell>
                      <TableCell>{entry.name}</TableCell>
                      <TableCell>{entry.totalExp}</TableCell>
                      <TableCell>{entry.relevantExp}</TableCell>
                      <TableCell>{entry.contact}</TableCell>
                      <TableCell>{entry.email}</TableCell>
                      <TableCell>{entry.availability}</TableCell>
                      <TableCell>{entry.rate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      )}
    </Container>
  );
};

export default FileUploadAndList;
