import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeProfile = () => {
  const [employeeData, setEmployeeData] = useState({
    employeeId: '',
    companyName: 'Baranwal Consultancy and Services',
    prefix: 'Mr.',
    firstName: '',
    lastName: '',
    personalEmail: '',
    officialEmail: '',
    personalContact: '',
    emergencyContact: '',
    emergencyContactRelation: '',
    dob: '',
    bloodGroup: '',
    gender: '',
    maritalStatus: 'Single',
    permanentAddress: { country: '', state: '', city: '', address: '' },
    currentAddress: { country: '', state: '', city: '', address: '' },
    dateOfJoining: '',
    employmentType: 'Permanent',
    employmentStatus: 'Probation',
    department: 'Account',
    designation: '',
    reportingTo: '',
    documents: {
      aadharCard: null,
      panCard: null,
      passportPhoto: null, 
      addressProof: null,
      educationalDocs: { tenth: null, twelfth: null, graduation: null }, 
    },
    linkedinLink: '',
    
  });
  const [isEditMode, setIsEditMode] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevState) => ({ ...prevState, [name]: value }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get('https://besto-hrms.onrender.com/api/employee/get-employee');
        const fetchedData = response.data;

        // Ensure data is available and complete before setting state
        if (fetchedData && fetchedData.documents) {
          const processedData = {
            ...fetchedData,
            documents: {
              ...fetchedData.documents,
              educationalDocs: {
                tenth: fetchedData.documents.educationalDocs?.tenth ,
                twelfth: fetchedData.documents.educationalDocs?.twelfth,
                graduation: fetchedData.documents.educationalDocs?.graduation,
              },
            },
          };
          
          setEmployeeData(processedData);
        } else {
          toast.warn('Incomplete employee data received');
        }
      } catch (error) {
        console.error('Error fetching employee details:', error);
      } 
    };

    fetchEmployeeDetails();
  }, []);

  const handleAddressChange = (addressType, field, value) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [addressType]: { ...prevState[addressType], [field]: value },
    }));
  };

// Handle file input change
const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
        if (['tenth', 'twelfth', 'graduation'].includes(name)) {
            // Handle educationalDocs separately
            setEmployeeData((prevState) => ({
                ...prevState,
                documents: {
                    ...prevState.documents,
                    educationalDocs: { ...prevState.documents.educationalDocs, [name]: file },
                },
            }));
        } else {
            // Handle other document fields
            setEmployeeData((prevState) => ({
                ...prevState,
                documents: { ...prevState.documents, [name]: file },
            }));
        }
    }
};

const handleSubmit = async (e) => {
    e.preventDefault();

    // Log employee data for debugging
    console.log("Employee Data:", employeeData);

    // Extract the documents part to upload separately
    const { documents, ...restOfEmployeeData } = employeeData;

    try {
        // 1. Send non-file employee data to employees endpoint
        await axios.post('https://besto-hrms.onrender.com/api/employee/employees', restOfEmployeeData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        toast.success('Employee details saved successfully.');

        // 2. If there are files, upload the documents
        if (documents && Object.keys(documents).length > 0) {
            await uploadFiles(documents);  // Call the function to handle file upload
        }
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error saving employee details. Please check the console for details.');
    }
};

// Function to upload files
const uploadFiles = async (documents) => {
    const formData = new FormData();

    // Append document files to FormData
    Object.keys(documents).forEach((key) => {
        const file = documents[key];
        if (key === 'educationalDocs' && file) {
            // Handle educational documents separately
            Object.keys(file).forEach((eduKey) => {
                const eduFile = file[eduKey];
                if (eduFile) {
                    formData.append(`educationalDocs[${eduKey}]`, eduFile);  // Add each educational doc file
                }
            });
        } else if (file) {
            formData.append(key, file);  // Add other document files like aadharCard, panCard, etc.
        }
    });

    try {
        // Send the formData with the files to the upload endpoint
        await axios.post('https://besto-hrms.onrender.com/api/employee/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        toast.success('Files uploaded successfully.');
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        toast.warn('No Files were uploaded.');
    }
};

const toggleEditMode = () => {
  setIsEditMode(!isEditMode);
};




  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Employee Profile</h2>

      {/* Employee ID */}
      <div className="mb-4">
        <label className="block text-gray-700">Employee ID</label>
        {isEditMode ? (
        <input
          type="text"
          name="employeeId"
          value={employeeData.employeeId}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      ) : (
        <p className="p-2 border border-gray-300 rounded">{employeeData.employeeId || 'N/A'}</p>
      )}
      </div>

      {/* Company Name */}
      <div className="mb-4">
        <label className="block text-gray-700">Company Name</label>
        {isEditMode ? (
        <select
          name="companyName"
          value={employeeData.companyName}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Baranwal Consultancy and Services">Baranwal Consultancy and Services</option>
          <option value="Bestowal Systems and Services Pvt. Ltd.">Bestowal Systems and Services Pvt. Ltd.</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.companyName || 'N/A'}</p>
        )}
      </div>

      {/* Prefix */}
      <div className="mb-4">
        <label className="block text-gray-700">Prefix</label>
        {isEditMode ? (
        <select
          name="prefix"
          value={employeeData.prefix}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Mr.">Mr.</option>
          <option value="Ms.">Ms.</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.prefix || 'N/A'}</p>
        )}
      </div>

      {/* First Name, Last Name */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">First Name</label>
          {isEditMode ? (
          <input
            type="text"
            name="firstName"
            value={employeeData.firstName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.firstName || 'N/A'}</p>
        )}
        </div>
        <div>
          <label className="block text-gray-700">Last Name</label>
          {isEditMode ? (
          <input
            type="text"
            name="lastName"
            value={employeeData.lastName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.lastName || 'N/A'}</p>
        )}
        </div>
      </div>

      {/* Emails */}
      <div className="mb-4">
        <label className="block text-gray-700">Personal Email</label>
        {isEditMode ? (
        <input
          type="email"
          name="personalEmail"
          value={employeeData.personalEmail}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      ) : (
        <p className="p-2 border border-gray-300 rounded">{employeeData.personalEmail || 'N/A'}</p>
      )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Official Email</label>
        <input
          type="email"
          name="officialEmail"
          value={employeeData.officialEmail}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
          readOnly={!isEditMode}
        />
      </div>

      {/* Contact Information */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Personal Contact</label>
          <input
            type="text"
            name="personalContact"
            value={employeeData.personalContact}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">Emergency Contact</label>
          <input
            type="text"
            name="emergencyContact"
            value={employeeData.emergencyContact}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Emergency Contact Relation</label>
        <input
          type="text"
          name="emergencyContactRelation"
          value={employeeData.emergencyContactRelation}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
          readOnly={!isEditMode}
        />
      </div>

      {/* Additional Fields */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={employeeData.dob ? employeeData.dob.slice(0, 10) : ''}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">Blood Group</label>
          <input
            type="text"
            name="bloodGroup"
            value={employeeData.bloodGroup}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Gender</label>
        {isEditMode ? (
        <select
          name="gender"
          value={employeeData.gender}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="">Select</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.gender || 'N/A'}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Marital Status</label>
        {isEditMode ? (
        <select
          name="maritalStatus"
          value={employeeData.maritalStatus}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Single">Single</option>
          <option value="Married">Married</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.maritalStatus || 'N/A'}</p>
        )}
      </div>

      {/* Address Fields */}
      <h3 className="text-lg font-semibold mt-6">Permanent Address</h3>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            value={employeeData.permanentAddress.country}
            onChange={(e) => handleAddressChange('permanentAddress', 'country', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">State</label>
          <input
            type="text"
            value={employeeData.permanentAddress.state}
            onChange={(e) => handleAddressChange('permanentAddress', 'state', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">City</label>
          <input
            type="text"
            value={employeeData.permanentAddress.city}
            onChange={(e) => handleAddressChange('permanentAddress', 'city', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">Address</label>
          <input
            type="text"
            value={employeeData.permanentAddress.address}
            onChange={(e) => handleAddressChange('permanentAddress', 'address', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
      </div>

      <h3 className="text-lg font-semibold mt-6">Current Address</h3>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            value={employeeData.currentAddress.country}
            onChange={(e) => handleAddressChange('currentAddress', 'country', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">State</label>
          <input
            type="text"
            value={employeeData.currentAddress.state}
            onChange={(e) => handleAddressChange('currentAddress', 'state', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">City</label>
          <input
            type="text"
            value={employeeData.currentAddress.city}
            onChange={(e) => handleAddressChange('currentAddress', 'city', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
        <div>
          <label className="block text-gray-700">Address</label>
          <input
            type="text"
            value={employeeData.currentAddress.address}
            onChange={(e) => handleAddressChange('currentAddress', 'address', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
            readOnly={!isEditMode}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Date of Joining</label>
        <input
          type="date"
          name="dateOfJoining"
          value={employeeData.dateOfJoining ? employeeData.dateOfJoining.slice(0, 10) : ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
          readOnly={!isEditMode}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Employment Type</label>
        {isEditMode ? (
        <select
          name="employmentType"
          value={employeeData.employmentType}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Permanent">Permanent</option>
          <option value="Trainee">Trainee</option>
          <option value="Intern">Intern</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.employmentType || 'N/A'}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Employment Status</label>
        {isEditMode ? (
        <select
          name="employmentStatus"
          value={employeeData.employmentStatus}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Probation">Probation</option>
          <option value="Confirmed">Confirmed</option>
          <option value="Resigned">Resigned</option>
          <option value="Relieved">Relieved</option>
          <option value="Settled">Settled</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.employmentStatus || 'N/A'}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Department</label>
        {isEditMode ? (
        <select
          name="department"
          value={employeeData.department}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Account">Account</option>
          <option value="Operations">Operations</option>
          <option value="Human Resources">Human Resources</option>
          <option value="IT">IT</option>
          <option value="Sales and Marketing">Sales and Marketing</option>
        </select>
        ) : (
          <p className="p-2 border border-gray-300 rounded">{employeeData.department || 'N/A'}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Designation</label>
        {isEditMode ? (
        <input
          type="text"
          name="designation"
          value={employeeData.designation}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      ) : (
        <p className="p-2 border border-gray-300 rounded">{employeeData.designation || 'N/A'}</p>
      )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Reporting To</label>
        {isEditMode ? (
        <input
          type="text"
          name="reportingTo"
          value={employeeData.reportingTo}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required // Assuming this field is required based on the error
        />
      ) : (
        <p className="p-2 border border-gray-300 rounded">{employeeData.reportingTo || 'N/A'}</p>
      )}
      </div>

      {/* Document Uploads */}
      <h3 className="text-lg font-semibold mt-6">Documents Upload</h3>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Aadhar Card</label>
          {isEditMode ? (
      <input
        type="file"
        name="aadharCard"
        onChange={handleFileChange}
        className="w-full p-2 border border-gray-300 rounded"
      />
    ) : employeeData.documents.aadharCard ? (
      <p className="p-2 border border-gray-300 rounded">
        <a
          href={`https://besto-hrms.onrender.com/${employeeData.documents.aadharCard}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          View Aadhar Card
        </a>
      </p>
    ) : (
      <p className="p-2 border border-gray-300 rounded">No document uploaded</p>
    )}
        </div>
        <div>
  <label className="block text-gray-700">PAN Card</label>
  {isEditMode ? (
    <input
      type="file"
      name="panCard"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData.documents.panCard ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
        href={`https://besto-hrms.onrender.com/${employeeData.documents.panCard}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View PAN Card
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">Not uploaded</p>
  )}
</div>
<div>
  <label className="block text-gray-700">Passport Photo</label>
  {isEditMode ? (
    <input
      type="file"
      name="passportPhoto"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData.documents.passportPhoto ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
         href={`https://besto-hrms.onrender.com/${employeeData.documents.passportPhoto}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View Passport Photo
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">Not uploaded</p>
  )}
</div>
<div>
  <label className="block text-gray-700">Address Proof</label>
  {isEditMode ? (
    <input
      type="file"
      name="addressProof"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData.documents.addressProof ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
        href={`https://besto-hrms.onrender.com/${employeeData.documents.addressProof}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View Address Proof
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">No document uploaded</p>
  )}
</div>
      </div>
      
   {/* Separate inputs for educational documents */}
<div className="mb-4">
  <label className="block text-gray-700">10th Grade Certificate</label>
  {isEditMode ? (
    <input
      type="file"
      name="tenth"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData?.documents?.educationalDocs?.tenth ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
        href={`https://besto-hrms.onrender.com/${employeeData.documents.educationalDocs.tenth}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View 10th Grade Certificate
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">No document uploaded</p>
  )}
</div>

<div className="mb-4">
  <label className="block text-gray-700">12th Grade Certificate</label>
  {isEditMode ? (
    <input
      type="file"
      name="twelfth"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData?.documents?.educationalDocs?.twelfth ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
        href={`https://besto-hrms.onrender.com/${employeeData.documents.educationalDocs.twelfth}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View 12th Grade Certificate
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">No document uploaded</p>
  )}
</div>

<div className="mb-4">
  <label className="block text-gray-700">Graduation Certificate</label>
  {isEditMode ? (
    <input
      type="file"
      name="graduation"
      onChange={handleFileChange}
      className="w-full p-2 border border-gray-300 rounded"
    />
  ) : employeeData?.documents?.educationalDocs?.graduation ? (
    <p className="p-2 border border-gray-300 rounded">
      <a
        href={`https://besto-hrms.onrender.com/${employeeData.documents.educationalDocs.graduation}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View Graduation Certificate
      </a>
    </p>
  ) : (
    <p className="p-2 border border-gray-300 rounded">No document uploaded</p>
  )}
</div>


     

      <div className="mb-4">
        <label className="block text-gray-700">LinkedIn Link</label>
        <input
          type="url"
          name="linkedinLink"
          value={employeeData.linkedinLink}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          readOnly={!isEditMode}
        />
      </div>


      <button
  type="button"
  onClick={toggleEditMode}
  className="bg-gray-500 text-white p-2 rounded mr-2"
>
  {isEditMode ? 'Cancel' : 'Edit'}
</button>


      {isEditMode && (
  <button
    type="submit"
    className="bg-blue-500 text-white p-2 rounded"
  >
    Save Changes
  </button>
)}

    </form>
  );
};

export default EmployeeProfile;
